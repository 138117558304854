.coasters-page {
  padding: 20px;
  background-color: #f9f9f9;
}

.coasters-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; /* Align items to the left */
}
