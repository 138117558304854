/* CartProduct.css */
.cart-product {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
}

.product-thumbnail {
  width: 50px; /* Adjust the size as needed */
  height: 50px; /* Adjust the size as needed */
  object-fit: cover; /* Ensure the image covers the area */
  margin-right: 10px; /* Space between image and title */
}

.cart-product-title {
  font-size: 11px; /* Reduced font size for web view */
  color: #333;
  margin: 0;
  flex: 1; /* Allow the title to take up available space */
}

.cart-product-quantity {
  font-size: 11px; /* Reduced font size for web view */
  color: #333;
  margin: 0;
  flex: 1; /* Allow the title to take up available space */
}

.total-price {
  font-size: 12px; /* Reduced font size for web view */
  color: #333;
  margin: 0 10px;
}

.product-button {
  padding: 8px 16px;
  background-color: #ff0000;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.product-button:hover {
  background-color: #cc0000;
}

@media (max-width: 768px) {
  .cart-product-title {
    font-size: 10px; /* Reduced font size for web view */
    color: #333;
    margin: 0;
    flex: 1; /* Allow the title to take up available space */
  }

  .cart-product-quantity {
    font-size: 10px; /* Reduced font size for web view */
    color: #333;
    margin: 0;
    flex: 1; /* Allow the title to take up available space */
  }

  .total-price {
    font-size: 1px; /* Reduced font size for mobile view */
    text-align: center; /* Center text for mobile view */
    width: 100%; /* Ensure it takes the full width */
    margin-bottom: 10px; /* Add some space between price and button */
  }

  .product-button {
    width: 100%; /* Make button full width on mobile view */
    text-align: center;
  }
}