/* About.css */
.about-header {
    font-size: 36px;
    color: #2c3004;
    text-align: center;
    margin: 20px 0;
  }
  
  .about-text {
    font-size: 24px;
    color: rgba(44, 48, 4, 0.8);
    text-align: justify;
    margin: 0 20px;
  }
  
  @media (max-width: 768px) {
    .about-header {
      font-size: 24px; /* Adjust font size for mobile view */
    }
  
    .about-text {
      font-size: 16px; /* Adjust font size for mobile view */
      text-align: center; /* Center text for mobile view */
    }
  }