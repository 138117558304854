.cart-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .cart-button:hover {
    background-color: #0056b3;
  }
  
  .cart-image {
    width: 24px; /* Adjust the size as needed */
    height: 24px; /* Adjust the size as needed */
  }

  .checkout-button {
    padding: 7px 20px; /* Increase padding for larger button */
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    white-space: nowrap; /* Prevent text wrapping */
    font-size: 14px; /* Increase font size */
  }
  
  .checkout-button:hover {
    background-color: #0056b3;
  }