.custom-navbar {
  background-color: aliceblue;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; /* Ensure the navbar stays above the banner */
  font-family: 'Arial', sans-serif;
  font-size: 17px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */
  border-bottom: 2px solid #ccc; /* Add a border to the bottom of the navbar */
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.logo-image {
  width: 300px; /* Adjust logo size as needed */
  width: 300px;
  height: auto;
  margin-right: 10px;
}

.custom-navbar .nav-link {
  color: #333; /* Change to match website color scheme */
  margin-right: 15px;
  transition: color 0.3s ease;
}

.custom-navbar .nav-link:hover {
  color: #007bff; /* Change to match website color scheme */
}

.custom-navbar .nav-link.active {
  font-weight: bold;
  color: #007bff; /* Change to match website color scheme */
}

.custom-navbar .navbar-toggler {
  border: none;
}

.custom-navbar .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

/* Media query for mobile view */
@media (max-width: 768px) {
  .logo-image {
    width: 200px; /* Decrease logo size for mobile view */
    width: 200px; 
  }
  
}

.moving-banner {
  width: 100%;
  background-color: aliceblue; /* Same background color as navbar */
  color: red; /* Text color red */
  text-align: center;
  padding: 10px 0;
  position: fixed;
  top: 110px; /* Adjust top position to be below the navbar */
  z-index: 999;
  font-size: 11px;
  animation: moveBanner 20s linear 1;
}

@media (min-width: 769px) {
  .moving-banner {
    top: 105px; /* Adjust top position to display below the checkout button in mobile view */
  }
}

@keyframes moveBanner {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}