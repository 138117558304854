.categories-page {
  padding: 20px;
}

.welcome-section {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.welcome-text {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  font-family: 'Cursive';
}

.welcome-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.banner-image {
  width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: contain; /* Ensure the image fits within the container without changing resolution */
}

.shop-now-button {
  font-size: 18px; /* Adjust font size to match web standards */
  padding: 10px 20px;
  font-family: 'Arial', sans-serif; /* Change to match website standards */
  background-color: #007bff; /* Change to match website color scheme */
  color: white; /* Ensure text color is readable */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.shop-now-button:hover {
  background-color: #0056b3; /* Darken the background color on hover */
}

.categories-section {
  background-color: white;
  padding: 20px;
}

.categories-title {
  font-family: 'Serif';
  font-size: 30px;
  text-align: center;
  margin-bottom: 20px;
}

.categories-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.category-item {
  width: 100%;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-container {
  position: relative;
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  overflow: hidden;
}

.category-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-name {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

/* Media query for larger screens */
@media (min-width: 768px) {
  .categories-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .category-item {
    flex: 1 1 22%; /* Adjust the width as needed */
    margin: 10px;
  }

  .welcome-section {
    flex-direction: row;
  }

  .welcome-text, .welcome-image {
    flex: 1;
  }

  .banner-image {
    height: 100%; /* Ensure the image takes the full height of the container */
    max-height: none; /* Remove the max-height restriction */
  }
}