.add-to-cart-button {
  padding: 5px 10px; /* Reduce padding for a smaller button */
  font-size: 14px; /* Reduce font size */
  background-color: rgb(32, 96, 234);
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-block; /* Ensure it doesn't take up unnecessary space */
  margin: 5px auto; /* Add spacing around the button */
}

.add-to-cart-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.add-to-cart-button:hover:not(:disabled) {
  background-color: #0710af;
}

.remove-from-cart-button {
  padding: 5px 10px; /* Reduce padding for a smaller button */
  font-size: 14px; /* Reduce font size */
  background-color: rgba(110, 6, 13, 0.8);
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-block; /* Ensure it doesn't take up unnecessary space */
  margin: 5px auto; /* Add spacing around the button */
}

.remove-from-cart-button:hover:not(:disabled) {
  background-color: rgba(203, 17, 29, 0.8);
}

.product-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 250px; /* Adjust the max-width as needed */
  height: 400px; /* Set a fixed height for the card */
  margin: 10px;
}

.product-card:hover {
  transform: translateY(-5px);
}

.product-image {
  width: 100%;
  height: 250px; /* Increase the height for the image */
   /* Ensure the image covers the area */
}

.product-image-bags {
  width: 100%;
  height: 250px; /* Increase the height for the image */
}


.product-info {
  padding: 10px; /* Reduce padding to accommodate the image */
  text-align: center;
  flex-grow: 1; /* Ensure the info section takes the remaining space */
}

.product-description {
  font-family: 'Arial', sans-serif;
  font-size: 12px; /* Reduce font size */
  color: #666;
  margin-bottom: 5px; /* Reduce margin */
}

.product-price {
  font-family: 'Arial', sans-serif;
  font-size: 16px; /* Reduce font size */
  color: hsl(212, 83%, 5%);
  margin-bottom: 5px; /* Reduce margin */
  font-weight: bold;
}

.product-header {
  text-align: center;
  margin-bottom: 20px;
  flex-direction: column;
  align-items: center;
}

.product-title {
  font-family: 'Arial', sans-serif;
  font-size: 12px;
  color: #0c336d;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center; /* Center the text */
  font-weight: bold;
}

.product-col {
  width: 300px;
  margin: 0px 10px 20px 0px; /* Adjust margin as needed */
}


.out-of-stock,
.added-to-cart {
  font-size: 12px; /* Reduce font size */
  font-weight: bold;
}

.out-of-stock {
  color: red;
}

.added-to-cart {
  color: blue;
}

.modal-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.product-page-title {
  font-family: 'Arial', sans-serif;
  font-size: 36px;
  color: #0c336d;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center; /* Center the text */
  width: 100%; /* Ensure it takes the full width */
}

/* Media query for mobile view */
@media (max-width: 767px) {
  .product-card {
    max-width: 100%; /* Ensure the card takes full width in mobile view */
    margin: 10px auto; /* Center the card horizontally */
    height: auto; /* Allow the card to adjust its height */
  }

  .product-image {
    height: 500px; /* Increase height for mobile view */
  }

  .product-image-bags {
    height: 500px; /* Increase height for mobile view */
  }

  .product-info {
    padding: 10px; /* Adjust padding for mobile view */
  }

  .product-col {
    flex: 1 1 100%; /* Ensure the card takes full width in mobile view */
    margin: 10px 0; /* Adjust margin for mobile view */
  }

  .product-title {
    font-size: 14px; /* Reduce font size for mobile view */
    width: 100%; /* Ensure it takes the full width */
    font-weight: bold;
  }

  .product-col {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    margin: 0 auto 20px auto; /* Center the product card and add margin at the bottom */
  }

  .product-page-title {
    font-family: 'Arial', sans-serif;
    font-size: 36px;
    color: #0c336d;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center; /* Center the text */
    width: 100%; /* Ensure it takes the full width */
  }

}