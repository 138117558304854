.overlay {
    position: fixed;
    top: 20;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Align items to the top */
    padding-top: 100px; /* Adjust this value based on your navbar height */
    overflow-y: auto; /* Enable vertical scrolling */
  }
  
  .order-summary-overlay {
    background-color: #e6ffe6; /* Pale green background */
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 800px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-height: 80vh; /* Limit the height of the overlay */
    overflow-y: auto; /* Enable vertical scrolling within the overlay */
    position: relative; /* Ensure the close button is positioned correctly */
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .banner {
    background-color: hwb(120 53% 28%); /* Slightly darker pale green */
    padding: 30px;
    text-align: center;
    margin-bottom: 30px;
    border-radius: 8px 8px 0 0;
    font-size: 14px; /* Reduced font size by 2 points */
  }
  
  .order-number {
    color: blue;
    font-weight: bold;
  }
  
  .continue-shopping-button {
    padding: 8px 16px;
    background-color: rgba(44, 48, 4, 0.8);
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .continue-shopping-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  .continue-shopping-button:hover:not(:disabled) {
    background-color: #218838;
  }
