.contact-page {
    padding: 20px;
    background-color: #d1e7f5;
  }
  
  .contact-page h1 {
    font-family: 'Arial', sans-serif;
    font-size: 36px;
    color: #0c336d;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .success-message {
    color: green;
    font-size: 16px;
    margin-top: 20px;
    text-align: center;
  }
  
  .submit-button {
    font-size: 30px;
    padding: 10px 20px;
    font-family: 'Serif';
    background-color: rgba(44, 48, 4, 0.8);
  }
  
  .submit-button:disabled {
    background-color: #ccc; /* Change the color for the disabled state */
    cursor: not-allowed;
  }
  
  .submit-button:hover:not(:disabled) {
    background-color: #0056b3;
  }
  
  .contact-form {
    text-align: center; /* Center justify the form fields */
  }
  
  .contact-form label {
    margin-bottom: 10px;
  }
  
  .contact-form input[type="text"],
  .contact-form input[type="email"],
  .contact-form textarea {
    width: 100%; /* Ensure all fields have the same width */
    max-width: 500px; /* Set a maximum width for the fields */
    height: auto;
    margin: 0 auto; /* Center the fields */
  }
  
  .contact-form textarea {
    height: 300px;
    padding: 5px; /* Increase padding for larger fields */
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px; /* Increase font size for better readability */
  }
  
  .contact-form button {
    padding: 10px 20px;
    background-color: #1181fa;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .contact-form button:hover {
    background-color: #45a049;
  }
  
  .contact-image {
    flex: 1; /* Reduce the flex value to make the image smaller */
    margin-right: 20px; /* Add space between the image and the form */
  }
  
  .contact-image img {
    width: 150px; /* Reduce the size of the contact image */
    height: auto;
  }
  
  .contact-form-container {
    flex: 2; /* Ensure the form container takes more space */
  }
  
  .text-success {
    color: red;
    margin-top: 20px; /* Ensure it appears a couple of lines below the button */
  }
  
  @media (max-width: 600px) {
    .row {
      flex-direction: column;
    }
  
    .col-lg-6 {
      margin-bottom: 20px;
    }
  
    .contact-image {
      display: none; /* Hide the contact image div on mobile */
    }
  
    .contact-form {
      text-align: left; /* Left justify the form fields */
    }
  
    .contact-form label,
    .contact-form input[type="text"],
    .contact-form input[type="email"],
    .contact-form textarea {
      width: 100%; /* Make the input fields and labels full width on mobile */
      max-width: none; /* Remove the maximum width */
      margin: 0; /* Remove the auto margin */
    }
  
    .contact-form textarea {
      height: 200px; /* Adjust height for mobile view */
      padding: 5px; /* Increase padding for larger fields */
      margin-bottom: 20px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 16px; /* Increase font size for better readability */
    }
  
    .contact-form button {
      width: 100%; /* Make the submit button full width on mobile */
    }
  }